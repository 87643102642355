.stickyNav{
display: block;

width: 100%;
height: 80px;
}
.navbar {
  background-color:white;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 0 10px #333;
      position: -webkit-sticky;
      box-sizing: border-box;

}
.Nav-master-container{
height: 100%;

}
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: black; 
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  vertical-align: middle;
  display: inline-block;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  /* grid-gap: 10px; */
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-menu  li {
  padding: 0 !important;
}

.nav-item {
  height: 100%;
}

.nav-links {
  color: #242222;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 1px solid rgba(36, 34, 34,0.5);

}

.nav-links:hover {
  border-bottom: 1px solid #242222;
  transition: all 0.3s ease-in-out;
}

.fa-bars {
  color: black;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100%; */
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  .nav-menu.active {
    background-color: #FFFFFF;
    left: 0;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    height: ;
    box-shadow: 0 10px 10px #242222;
  }

  .nav-links {
    text-align: center;
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #242222;
    color: #fff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    -webkit-appearance: none;
    max-width: 150px;
    transform: translate(0, 65%);
    vertical-align: middle;
    display: inline-block;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: black;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #242222;
    transition: all 1s ease-in-out;
  }

  .nav-links-mobile:hover {
    background: #FFFFFF;
    color: #242424;
    transition: all 1s ease-in-out;
  }
}

@media screen and (max-height: 580px) {
  .nav-links {
    text-align: center;
    padding: 1rem;
    width: 100%;
    display: table;
    font-size: 1rem;
  }
  
  .nav-item {
    height: 100%;
  }
}

@media only screen and (min-width : 321px) and (orientation: landscape) {
  .nav-links {
    text-align: center;
    padding: 1rem;
    font-size: 1rem;
  }
  .nav-item {
    height: 100%;
    width: 50%;
  }
  .nav-menu{
    flex-wrap: wrap;
    max-height: 300px;
  }

  li:nth-child(6) nav-links{
  margin-left: 1rem;
}
}