.find-me-container{
    padding: 0 2rem 2.5rem 2rem;
}

.find-me-underline{
}

.find-me-header{
}

.find-me-content h3{
    /* padding-bottom: .75rem; */
    text-align:left;
    hanging-punctuation: first;
    font-weight: 300;
    text-transform:capitalize;
}

.find-me-content{
    padding-bottom: 1.25rem;
}