:root {
  --green: #71A300;
  --yellow: #FFCD00;
  --teal: #008E92;
  --purple: #653C5F;
  --orange: #DF6F34;
}
/*----------------------------------------------*/
/*---------------home page styling---------------*/
/*----------------------------------------------*/
.Home-bg{
background-color: var(--green) !important;
align-items: center; 
 justify-content: center;
 background-image:  url("../images/welcome2.png");
 background-repeat: no-repeat;
 background-size: auto 50%;
 background-position-x: -5rem;
}

.home-heart-sheild{
stroke: black;
mix-blend-mode: multiply;
width:auto; 
height: 40vh;
margin: -40px;
z-index: 0;

}

.Home-bg .hero-copy{ 
z-index: 1;

}

.LL-sanctuary{
  display: inline-block;
  
}

.LL-sanctuary li{
  box-sizing: border-box;
  padding: .15rem;
  list-style: none;
}
.pillar-underline {
margin-top: .25rem;  
  width: 65px !important;
}
.conference-room-underline{
  margin-top: .25rem;  
    width: 65px !important;
  }

 .splashSection .floor-1-bg {
background-image: url("../images/QH_headquarters_dublin_3-2.png");
background-blend-mode: multiply;
background-position: -15rem -1rem;
}

.floor-1-bg{
background-color: var(--teal) ;
}



.floor-2-bg{
background-color: var(--green) !important;
}

.splashSection .floor-2-bg{
background-image: url("../images/QH_headquarters_dublin_4-2.png");
background-blend-mode: multiply;
background-position: -100rem -5rem;
}

.floor-3-bg{
background-color: var(--purple) !important;

}

.splashSection .floor-3-bg{
background-color: var(--purple) !important;
background-image: url("../images/QH_headquarters_dublin_3-2.png");
background-blend-mode: multiply;
background-position: -45rem -45rem;
}

.floor-4-bg{
background-color: var(--orange) !important;

}

.splashSection .floor-4-bg{
background-image: url("../images/QH_headquarters_dublin_3-2.png");
background-blend-mode: multiply;
background-position: -75rem -80rem;
}

.splashSection .floor-LL-bg{
background-color: var(--yellow) !important;
color: black !important;
background-image: url("../images/QH_headquarters_dublin_3-2.png");
background-blend-mode: multiply;
background-position: -75rem -90rem;
}

.floor-LL-bg .btn--outline{
    background-color: transparent;
    color: #242424;
    padding: 8px 15px;
    transition: all 0.3s ease-out;
    border: 2px solid var(--primary);
}
.LL-egg{
  background-color: transparent !important;
  color: black !important;
}

h2{
font-weight: normal;
}

.section-header{
  color: #828282;
}

.LL-egg .easter-egg-content {
  color: black !important;

  }

.LL-egg .btn--small{
    padding: 8px 15px;
    font-size: 18px !important;
    font-weight: 600;
    color: black;
    max-width: 200px;
    border: 2px solid black;
}
/*----------------------------------------------*/
/*---------------FLoor LL styling---------------*/
/*----------------------------------------------*/

.floor-LL-bg{
background-color: var(--yellow) !important;
color: black !important;
}

.floor-LL-bg h1{
color: black !important;
}

.floor-LL-bg p{
color: black !important;
}

.floor-LL-bg .pillar-underline{
border: 1px black solid !important;
}

.floor-LL-bg .conference-room-underline {
  border: 1px black solid !important;

}

@media screen and (min-width: 768px){
    .home-pillar-container{
    justify-content:center;
    text-align: center;
    }

    p{
    font-size: 20px;
    }

    .pillar-cards {
    width: 200px !important;
    max-width: 200px !important;
    }

    .pillar-img{
    width: 200px !important;
    max-width: 200px !important;

    }
    h3{
    font-size:1.5rem; 
    }

}

.text-content-container > h2{
font-weight: 400;
}

.text-content-container > p{
padding-bottom: 3rem;}

.easter-egg-card .floor-4-bg{
top:25% !important;
}

.caution{
z-index:99;
position: absolute;
filter: drop-shadow(0 0 10px rgb(97, 97, 97));
transform: rotate(20deg);
width: 200%;
top: 25%;
left: -50%;
}

.caution-test{
  z-index:99;
position: absolute;
filter: drop-shadow(0 0 10px rgb(97, 97, 97));
transform: rotate(20deg);
width: 200%;
top: 25%;
   left: -50%;
}

.caution-container{
position: absolute;
  height: 100vh; 
  width:100vw;
  overflow: hidden;
}

.hard-hat{
transform: translate(-50%);
left: 50%;

position: absolute;
bottom: 5%;
height: auto; 
max-width: 350px;
}

@media screen and (min-width: 350px) {
.hard-hat{
transform: translate(-50%);
left: 50%;

position: absolute;
bottom: 5%;
height: auto; 
max-width: 300px;
}
  }

  @media only screen and (min-width : 321px) and (orientation: landscape) {


  .easter-egg-card {
      height: 200vh !important;
      position: absolute;
      top: 75% !important;
      width: 100%;
      left: 0;
      z-index: -1;
      border-top-right-radius: 25px;
      border-top-left-radius: 25px;
  }
  .caution{
      z-index: 99;
      position: absolute;
      filter: drop-shadow(0 0 10px rgb(97, 97, 97));
      transform: rotate(
      0
      );
      width: 100%;
      top: 25%;
      left: 0;
  }
  .hard-hat{
  display: none !important;


  }
  .hero-container  > h1{
  padding-top: 0rem !important;
  }
  .furniture-container{
  display: flex;
  flex-direction: row ;
  }
  .furniture-image{
  width: 50% !important;
  }
  .furniture-headline{
  width: 50% !important;
  padding: 3rem 0 0 2rem;
  }


  .pillar-container{
  display: flex;
  flex-wrap: wrap;
  }
  .section-header{
  width: 100%;
  }
  .pillar-content{
  width: 50%;
  padding: 2rem 2rem 0 0;
  top: 50%;
  }
  .pillar{
  width: 50%;
  }
  .Home-bg{
    background-color: var(--green) !important;
    align-items: center; 
     justify-content: center;
     background-image:  url("../images/welcome-LND.png");
     background-repeat: no-repeat;
     background-size: 100% auto;
     background-position-x: -5rem;
    }
    .Home-bg .hero-copy{ 
    position: absolute;
    right: 2rem;
  }
  .home-heart-sheild{
    stroke: black;
    mix-blend-mode: multiply;
    width:auto; 
    height: 60vh;
    margin: 0;
    position: absolute;
    right: 7rem;
    z-index: 0;
  }
  .floor-to-ceiling{
    background-color: var(--yellow) !important;
    background-image: none;
    object-fit: contain;
    height: 100%;
    justify-content: center;
    background-size: 0% auto !important;
  }
  .text-content-container p {
    padding: 0;
    padding-bottom: 0 !important;
  }
}
@media only screen and (min-device-width: 480px) 
                   and (max-device-width: 640px) 
                   and (orientation: landscape) {

                   
                   }