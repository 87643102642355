.text-content-container{
    padding: 4rem 2rem 0 2rem;

}

.text-content-container h2{
    text-align: left;
    padding-bottom: 1.5rem;
}
.text-content-container p{
    padding-bottom: 1.5rem;
}

.floor-to-ceiling{
      background: url("../images/210215_building_graphics_3306_TOTO_wall_more_yellow2.png")  bottom -4rem center/cover no-repeat;
      object-fit: contain;
      background-size: auto 100%;
      height: 100vh;
      justify-content: center;
      padding: 2rem;
      background-color: rgb(231,174,29);
    background-size: 100% auto;
}

.section-header h2{
    padding-bottom: 0;

}

@media only screen and (min-width : 321px) and (orientation: landscape) {

    .floor-to-ceiling{
      background-color: var(--yellow) !important;
      background-image: none;
      object-fit: contain;
      height: 100%;
      justify-content: center;
      background-size: 0% auto !important;
    }
    .text-content-container p {
      padding: 0;
      padding-bottom: 0 !important;
    }
  }