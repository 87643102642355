.conference-room-container{
    padding:4rem 2rem;
    color: white;}
.conference-room-underline{
}

.conference-room-header{
    padding-bottom: 2.5rem;

}

.conference-room-container li{
    font-size: 16px;
    list-style: none;
    text-transform: uppercase;
    line-height: 2rem;
}

.conference-room-container hr { 
    display: block; 
    height: 1px;
    border: 0; 
    border-top: 1px solid white;
}