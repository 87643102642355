:root{
    --primary: #fff;
}

.btn{
    padding: 8px 15px;
    border-radius: 30px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary{
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline{
    background-color: transparent;
    color: #fff;
    padding: 8px 15px;
    transition: all 0.3s ease-out;
    border: 2px solid var(--primary);
}
.btn--small{
    padding: 8px 15px;
    font-size: 20px;
    font-weight: 600;
    max-width: 200px;
}
.btn--medium{
    padding: 8px 15px;
    font-size: 20px;
    width: 125px;
}

.btn--large{
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover{
    background: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
}
