.easter-egg-container{
    padding: 2rem 3.5rem;
    color: white;
}

.easter-egg-image{
  filter: drop-shadow(0 2.8px 2.2px rgb(97, 97, 97));
  max-height: 350px;
  width: auto;
  max-width: 100%;
  height: auto;
  
}

.easter-egg-image-container{
justify-content: center;
text-align: center;

}

.easter-egg-card{
    Height: 125vh;
    position: absolute;
    top: 50%;
    width: 100%;
    left:0;
    z-index: -1;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
}

.scarecrow .easter-egg-card{
    top: 35%;
}

.lego .easter-egg-card{
    top: 45%;
}

.the-birds .easter-egg-card{
    top: 40%;
}

.easter-egg-content{
    padding: 2rem 0;
    text-align: left;
}
.easter-egg-content h1{
    padding: 0 0 2rem 0;
    text-align: left;
}
.easter-egg-content p{
    padding: 0 0 2.5rem 0;
}

.easter-egg-card-container{
    overflow: hidden;
}

.easter-egg-content > h1{
    text-transform: uppercase;
}



@media screen and (min-height: 800px) {
.easter-egg-card {
    Height: 115vh ;
    position: absolute;
    top: 35% ;
    width: 100%;
    left: 0;
    z-index: -1;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
}}
