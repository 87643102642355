.cards {
    padding: 0 2rem 2.5rem 2rem;
    background: #fff;
  }
  
  h1 {
    text-align: center;
  }
  h5{
    font-family: 'Avenir Next', sans-serif;
    font-weight: normal;
  }
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    /* margin: 50px 0 45px; */
  }
  
  .cards__items {
    /* margin-bottom: 24px; */
    width: 100%;
    height: 200px;
  }
  
  .cards__item {
    display: flex;
    height: 100%;

    /* margin: 0 1rem; */
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    /* content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box; */
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
    /* background-color: #F2F3EC; */

  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    /* background-color: #71A300; */

    padding: 20px 30px ;
  }
  
  .cards__item__text {
    /* color: #252e48; */
    font-size: 18px;
    line-height: 24px;
    z-index:12;
  }
  .green{
    background-color: #71A300 !important;
  color: white !important;
  }

  .yellow{
    background-color: #FFCD00 !important;
    color: #252e48 !important;
  }

  .blue{  
    background-color: #008E92 !important;
    color: white !important;
  }

  .orange{ 
     background-color: #DF6F34 !important;
    color: white !important;
  }

  .purple{  
    background-color: #653C5F !important;
    color: white !important;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }
  
  @media only screen and (min-width : 321px) and (orientation: landscape) {
    .cards__container {
      flex-flow: row;
      flex-wrap: wrap;
      align-items: center;
      max-width: 1120px;
      margin: 0 auto;

    }
    .cards__item {
      display: flex;
      /* height: auto; */
      width: 50%;
      padding:  1rem;
      /* margin: 0 1rem; */
      border-radius: 10px;
    }
    .cards_items{
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: center;

    }
  }