.button-container{
    padding:0 ;
    text-align: right;

}

.button-row{
    padding: 2rem;
    align-content: right;
}

.button-flr-1{
    background-color: #008E92;
    background-image: url("../images/QH_headquarters_dublin_3-2.png");
    background-blend-mode: multiply;
    background-position: -60rem -10rem;
    mix-blend-mode:multiply;

}

.button-flr-2{
    background-color: #71A300;
    background-image: url("../images/QH_headquarters_dublin_4-2.png");
    background-blend-mode: multiply;
    mix-blend-mode:multiply;

    background-position: -20rem -15rem;
}

.button-flr-3{
    background-color: #653C5F;
    background-image: url("../images/QH_headquarters_dublin_3-2.png");
    background-blend-mode: multiply;
    mix-blend-mode:multiply;

    background-position: -30rem -20rem;
}

.button-flr-4{
    background-color: #DF6F34;
    background-image: url("../images/QH_headquarters_dublin_4-2.png");
    background-blend-mode: multiply;
    mix-blend-mode:multiply;
    background-position: -75rem -80rem;
}

.button-flr-ll{
    background-color: #FFCD00;
    background-image: url("../images/QH_headquarters_dublin_LL-2.png");
    background-blend-mode: multiply;
    mix-blend-mode:multiply;
    background-position: -12rem 35rem;
    
}

.button-flr-ll .btn--outline{
background-color: transparent;
color: black;
padding: 8px 15px;
transition: all 0.3s ease-out;
border: 2px solid black;
}