.hero-container{
    min-height: 100vh;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    object-fit: contain;
    padding: 2rem;
}

.hero-container > h1{
    color: #fff;
    font-size: 100px;
   text-align: left;
   padding-top: 2rem;
}

.hero-container .pillar-underline{
border: transparent;
border-top: 1px white solid;
}
.hero-container >p {
    margin-top: 8px;
    color: #fff;
    font-size: 16px;

}

.Welcome{
    font-weight: 200;
    font-size: 56px;
    line-height: 79px;
    margin-top: 300px;
}

.Home{
    text-transform: uppercase;
    font-size: 95px;
    font-weight: 700;
    line-height: 79px;
}

.hero-btns{
    margin-top: 32px;
}

.hero-btns .btn{
    margin: 6px;
}

.fa-play-circle{
    margin-left: 4px;
}

.heroCallOut{
font-size: 24px;
line-height: 32px;

}
@media screen and (max-width: 960px){
    .hero-container > h1{
       font-size: 70px;
        /*margin-top: -150px;*/
    } 
}
@media screen and (max-width: 768px){
    .hero-container > h1{
       font-size: 3.25rem;
       /* margin-top: -100px;*/
    } 
    .hero-container >p {
        font-size: 18px;
        line-height: 28px;
        padding-top: 2.25rem;
    }
    .btn-mobile {
        display: block;
        text-decoration: none;
    }
    .btn{
        width:100%;

}
}
@media screen and (min-width: 768px){
    .hero-container > h1{
       font-size: 62px;
       /* margin-top: -100px;*/
    } 
    .hero-container >p {
        font-size: 18px;
        line-height: 28px;
        padding-top: 2.25rem;
    }
    .btn-mobile {
        display: block;
        text-decoration: none;
    }
    .btn{
        width:100%;
    }
    .Welcome{
    font-weight: 200;
    font-size: 100px;
    line-height: 79px;
    margin-top: 300px;
    }

    .Home{
        text-transform: uppercase;
        font-size: 150px;
        font-weight: 700;
        line-height: 160px;
    }

    p{
    font-size: 18px;
    }
}

