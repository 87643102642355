.footer-container{
    background-color: #242424;
    padding: 3rem 0 3rem 0;
    /* display: flex;
    justify-content: center; 
    align-items: center;
    justify-content: center;
    width: 100%; */
}

.footer-subscription{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
}

.footer-subscription-heading {
   
    margin-bottom: 24px;
   
    font-size: 24px;
}

.footerhr{
    border-top: 1px solid white;
}

.Logo{
    width: 100%;
    padding: 0 5rem;
    max-width: 300px;
}

.footer-links{
    width: 100%;
    max-width: 1000px;
    display: flex; 
    justify-content: center;
}

.footer-link-wrapper{
    display: flex;

}

.footer-link-items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px; 
    box-sizing: border-box;
    text-align: center ;
    justify-content: center;
    list-style-type:none;
}

.links{
    text-align: center ;
    justify-content: center; 
    margin: auto;

}

.footer-link-items h2{
    margin-bottom: 16px; 

}

.footer-link-items >h2{
    color: #fff;  
}

.footer-link-items a{
    color: #fff;  
    text-decoration: none;
    margin-bottom: 1.5rem;
}

.footer-link-items a:hover{
    color: #e9e9e9;
    transition: 0.3s ease-out;

}

.footer-email-form h2{
    margin-bottom: 32px;
}

.footer-input::placeholder{
color: #b1b1b1;
}

/*Social icon*/

.social-icon-link{
    color: #fff;
    font-size: 24px;
}

.social-media{
    max-width: 1000px;
    width: 100%;
}

.social-media-wrap{
    align-items: center; 
    max-width: 1000px;
    width: 100%;
    margin: 2rem 0 0 0;   
    padding: 0 4rem;   

}

.social-icons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;

}

.social-logo{
    color: #fff;
    justify-content: space-between;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem; 
    display: flex;
    justify-content: space-between;   
}

.website-rights{
    color: #fff;
    margin-bottom: 16px;
}

.footer-link-items-secondary p{
    font-size: 11px;
    color: white ;
    padding: 1rem 0 ;
}

.footer-link-items-secondary{
    padding: 2rem 0 0 0;
    font-size: 14px ;
    text-align: center;
    box-sizing: border-box;
    color: white;
    
}

.footer-link-items-secondary a{
    color: #fff;  
    text-decoration: none;
    margin-bottom: 8px;
}

.footer-link-items-secondary a:hover{
    color: #e9e9e9;
    transition: 0.3s ease-out;
}

@media screen and(max-width: 820px){
    .footer-links{
        padding-top: 2rem;
    }
   .footer-link-items{
       text-align: center;
       
   }
  
    .footer-link-wrapper{
        flex-direction: column;
        
    }
    .social-media-wrap{
        flex-direction: wrap;
        justify-content: space-between;

    }
  
    
}

@media screen and (max-height: 580px) {
    .footer-link-items-secondary{
        font-size: 11px ;
    }
  }