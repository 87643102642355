.member-quote-container{
    padding: 4rem 2rem;
}

.member-quote-underline{
}

.member-quote-header{
    padding-bottom: 2.5rem;
}

.member-quote-content > h1{
    padding-bottom: .75rem;
    text-align:left;
}

.member-quote-content{
    padding-bottom: 1.5rem;
}