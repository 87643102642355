.desktop-splash-container{
    background: rgb(113,163,0);
    background: linear-gradient(217deg, rgba(113,163,0,1) 7%, rgba(0,142,146,1) 77%);
    height: 100vh;
    color: white;
}

.desktop-splash-content{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 15%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

}

.desktop-splash-content > h1{
    font-family: "Avenir Next" , sans-serif;
    font-weight: 700;
    text-align: left !important;
    font-size: 10rem;
    padding-bottom: 1.5rem;
}

.desktop-splash-content > p{
    font-family: "Avenir Next" , sans-serif;
    font-weight: 300;
    text-align: left !important;
    font-size: 1.5rem;
}